import React from 'react';
import { useState } from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Pressroom() {
	const [show, setShow] = useState(false);
	const [thisImage, setThisImage] = useState('');

	const handleClose = () => setShow(false);
	const handleShow = (imageLink) => {
		setShow(true);
		setThisImage(imageLink);
	};
	return (
		<div>
			<Navbar></Navbar>
			<div className='container content p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Press Room</h2>

					<div className='row pt-2'>
						<div className='row py-2'>
							<a className='btn text-light btn-success' href="https://www.enca.com/lifestyle-top-stories-videos/watch-dont-smile-youre-camera-nmb-tells-criminals">
								<span class='me-2'>🌟</span>
                WATCH | Don't smile, you're on camera - NMB tells criminals
							</a>
						</div>
						<div className='row py-2'>
							<a className='btn text-light btn-success' href="https://www.heraldlive.co.za/news/2024-11-13-nelson-mandela-bays-revolutionary-new-security-system-unveiled/">
								<span class='me-2'>🌟</span>
                Nelson Mandela Bay’s revolutionary new security system unveiled
							</a>
						</div>

						<div className='row py-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/bayhealth.png')}>
								{/* <span class='me-2'>🌟</span> */}
								Bay heath
							</button>
						</div>

						<div className='row py-2'>
							<a className='btn text-light btn-success' href='https://www.enca.com/news/eastern-cape-health-department-upgrades-security-systems-fight-crime' target='__blank'>
								{/* <span class='me-2'>🌟</span> */}
								Eastern Cape health department upgrades security systems to fight crime{' '}
							</a>
						</div>

						<div className='row py-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/eyes_in_the_sky.jpeg')}>
								Eyes in the sky' to home in on crime in inner city
							</button>
						</div>

						<div className='row p-2'>
							<div className='d-flex px-0'>
								<button className='btn text-light btn-success flex-grow-me-1 me-2 w-100' onClick={() => (window.location.href = 'https://www.heraldlive.co.za/news/2023-06-02-new-system-to-boost-security-at-hospital-clinics/')}>
									New system to boost security at hospital, clinics
								</button>
								{/* </div> */}
								{/* <div className='col-1'> */}
								<button className='btn text-light btn-success me-2' onClick={() => (window.location.href = 'https://fb.watch/kVNVxSslMf/?mibextid=l2pjGR')}>
									(Article)
								</button>
								{/* </div> */}
								{/* <div className='col-1'> */}
								<button className='btn text-light btn-success' onClick={() => (window.location.href = 'https://fb.watch/kVNVxSslMf/?mibextid=l2pjGR')}>
									(Interview)
								</button>
							</div>
						</div>

						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => (window.location.href = 'http://www.securitysa.com/article.aspx?pklarticleid=6708')}>
								Keeping a watchful eye on CBD crime
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/article-lorraine-crime-operation-jul12.jpg')}>
								Lorraine on the watch
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/baaise-loerbroer.jpg')}>
								Baaise 'loerbroer' n eerste vir SA
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/Baaise-loerbroer-n-eerste-vir-SA-Die-Burger.jpg')}>
								Surveillance vehicle the key to Bay crackdown on cable theft
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/beach-patrol-cops.jpg')}>
								Beach Patrol cops "just do it"
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/beheer-kamer.jpg')}>
								Kamer wil baai beheer
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/large-number-of-lifeguards.jpg')}>
								Large number of additional lifeguard to patrol beached during the holiday season
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success' onClick={() => handleShow('/img/press/wind-and-solar.jpg')}>
								Wind and Solar to thwart theft
							</button>
						</div>
						<div className='row p-2'>
							<button className='btn text-light btn-success ' onClick={() => handleShow('/img/press/massive-dagga-bust.jpeg')}>
								{' '}
								Massive Dagga Bust
							</button>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Woohoo, you are reading this text in a modal!
					<img src={thisImage} width='100%'></img>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<footer class='text-center text-lg-start bg-light text-muted mt-auto '>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

